import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { ResolverOptions } from './resolver-options';

@Injectable({
    providedIn: 'root'
})
export class MediaPathResolver {
    constructor(@Inject(LOCALE_ID) private locale: string) {}

    public resolve(options: ResolverOptions): string {
        if (options.localize) {
            return `${options.host}/${options.path}/${this.locale}/${options.name}`;
        }

        return `${options.host}/${options.path}/${options.name}`;
    }
}
